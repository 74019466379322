@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@tailwind base;
@tailwind components;

// Additional styles
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
@import 'additional-styles/flatpickr.scss';
@import 'additional-styles/theme.scss';

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@tailwind utilities;

.widget_branding  {
    display: none;
}

@media screen and (min-width: 1024px) {
  .hideBarIfSmall {
      display: none !important;
  }
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

.percent::after {
  content: '%';
  font-size: 18px;
  padding-left: 10px;
}

.table {
 width: 100%
}

.boxID{
  height: 30px;
  background: black;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  margin-top: 10px;
}

.boxIDContent{
  height: auto;
  min-height: 200px;
  background: black;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  margin-top: 2px;
}

.boxheaderText{
   color: white;
   margin-left: 20px;
   font-weight: 800;
}

.boxcontentText{
  color: white;
}

.panel {
  // border: 1px #00000038 solid;
  padding-right: 30px;
  border-radius: 10px;
  // box-shadow: 1px 1px 3px 0px #0000002e;
  width: 450px
}
